import React, { useState, useRef } from "react";
import FileInput from "./FileInput";
import SelectInput from "./SelectInput";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { countriesEs, countriesEn } from "../countries";
import "./Form.css";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function renderMassInstructions(lang) {
  if (lang === "es") {
    return (
      <div className="mass-instructions">
        <p>
          Entendemos y esperamos que su proyecto evolucione durante su tiempo en
          la residencia. Esta solicitud nos ayuda a comprender sus procesos
          artísticos y a asegurarnos de que su proyecto sea compatible con
          nuestros espacios.
        </p>
        <p>
          <b>
            En una página o menos, dentro de un PDF, incluye la siguiente
            información:
          </b>
        </p>
        <ol>
          <li>
            Describe brevemente quién eres como persona y como artista/creador,
            destacando experiencias y perspectivas que informe tu práctica
            artística.{" "}
          </li>
          <li>
            Brevemente explica los temas y enfoques centrales de tu trabajo.
          </li>
          <li>
            Comparte una propuesta de proyecto que describa la obra que planeas
            crear durante tu tiempo en la residencia de MASS X Cobertizo.
            Incluye una lista de materiales, equipo que podrías necesitar para
            empezar y un breve plan para completar este proyecto durante el
            periodo de residencia. Sabemos que este plan puede evolucionar, pero
            esta información nos ayudará a asegurarnos de que nuestras
            instalaciones puedan apoyar tu trabajo
          </li>
          <li>
            La exhibición final sera ubicada en MASS Gallery en Austin en
            agosto. Dado nuestro presupuesto limitado para el envío, describe
            cómo se podría transportar tu trabajo desde Cobertizo, MX, a Austin,
            TX. La residencia se hará cargo de todos los gastos de envío. Por
            ejemplo:
            <ul>
              <li>
                Una pieza de videoarte que pueda transferirse digitalmente{" "}
              </li>
              <li>Un tapiz de tela, dibujo o pintura pequeña </li>
              <li>Pequeñas esculturas</li>
            </ul>
          </li>
          <li>
            ¿Cómo beneficiaría tu práctica artística participar en MASS X
            Cobertizo en este intercambio transnacional de artistas?
          </li>
        </ol>
      </div>
    );
  }

  return (
    <div className="mass-instructions">
      <p>
        We understand and expect and encourage your project will evolve during
        the residency. This application helps us understand your working methods
        and ensure your project aligns with our facilities and limitations.{" "}
      </p>
      <p>
        <b>
          Within a page or less in a PDF please include the following
          information:
        </b>
      </p>
      <ol>
        <li>
          Briefly describe yourself as both a person and an artist/maker,
          highlighting experiences and perspectives that inform your artistic
          practice
        </li>
        <li>
          Provide a concise version of your artist statement that captures the
          core themes and approaches in your work.
        </li>
        <li>
          Share a project description outlining the artwork you plan to create
          during your time at the MASS X Cobertizo residency. Include a general
          list of materials, equipment you might need to start, and a brief
          timeline for completing this project during the residency period.
          Again, we understand this plan may evolve, but this information will
          help us ensure our facilities can support your work.
        </li>
        <li>
          The final exhibition will take place at MASS Gallery in Austin in
          August. Given our limited shipping budget please describe how your
          work could be transported from Cobertizo, MX to Austin, TX. The
          residency will cover all shipping expenses. For example:
          <ul>
            <li>A video art piece that can be digitally transferred </li>
            <li>A fabric tapestry, drawing, or small painting</li>
            <li>Small sculptures </li>
          </ul>
        </li>
        <li>
          How would creating work at Cobertizo and participating in this
          transnational exchange of artists benefit your artistic practice?
        </li>
      </ol>
    </div>
  );
}

const isDev = process.env.NODE_ENV === "development";

const Form = (props) => {
  const currentDate = new Date(),
    currentDay = currentDate.getDate(),
    currentMonth = currentDate.getMonth(),
    currentYear = currentDate.getFullYear(),
    captchaRef = useRef(1),
    { lang, program, periods } = props;

  console.log(program.id);

  const [name, setName] = useState(""),
    [email, setEmail] = useState(""),
    [reference, setReference] = useState(""),
    [lastname, setLastname] = useState(""),
    [day, setDay] = useState(currentDay),
    [month, setMoth] = useState(currentMonth),
    [year, setYear] = useState(currentYear),
    [nationality, setNationality] = useState(""),
    [periodSelected, setPeriodSelected] = useState(""),
    [bioFile, setBioFile] = useState(null),
    [presentationFile, setPresentationFile] = useState(null),
    [portfolioFile, setPortfolioFile] = useState(null),
    [residence, setResidence] = useState(""),
    [error, setError] = useState(""),
    [isSending, setIsSending] = useState(false),
    [successSent, setSuccessSent] = useState(false),
    [massFile, setMassFile] = useState(null);

  const countries = lang === "es" ? countriesEs : countriesEn;

  const onClick = () => {
    if (!email.trim().length)
      return setError(
        lang === "es" ? "* Correo electrónico requerido" : "* E-mail required"
      );
    if (!validateEmail(email))
      return setError(
        lang === "es"
          ? "* Correo electrónico no valido"
          : "* Not a valid E-mail"
      );
    if (!name.trim().length || !lastname.trim().length)
      return setError(
        lang === "es" ? "* Nombre completo requerido" : "* Full name required"
      );
    if (!day || !month || !year)
      return setError(
        lang === "es"
          ? "* Fecha de nacimiento requerido"
          : "* Date of birth required"
      );
    if (!nationality.trim().length)
      return setError(
        lang === "es" ? "* Lugar de nacimiento requerido" : "* Country required"
      );
    if (!residence.trim().length)
      return setError(
        lang === "es"
          ? "* País de residencia requerido"
          : "* Country of residence required"
      );

    if (program.id === "p2") {
      if (!bioFile || !massFile || !portfolioFile)
        return setError(
          lang === "es"
            ? "* Es necesario que adjuntes este archivo para continuar"
            : "* Files required"
        );
    } else {
      if (!bioFile || !presentationFile || !portfolioFile)
        return setError(
          lang === "es"
            ? "* Es necesario que adjuntes este archivo para continuar"
            : "* Files required"
        );
    }

    if (!periodSelected.trim().length)
      return setError(
        lang === "es" ? "* Selecciona un periodo" : "Select a period required"
      );

    let date = `${year}-${month < 10 ? `0${month}` : month}-${
      day < 10 ? `0${day}` : day
    }`;
    var form = new FormData();
    form.append("action", "site_signin");
    form.append("signin_email", email);
    form.append("signin_name", name);
    form.append("signin_lastname", lastname);
    form.append("signin_bday", date);
    form.append("signin_city_origin", nationality);
    form.append("signin_city_current", residence);
    form.append("signin_cv", bioFile, `cv_${name}_${date}.pdf`);
    if (program.id === "p2") {
      form.append("signin_mass_format", massFile, `mass_${name}_${date}.pdf`);
    } else {
      form.append(
        "signin_letter",
        presentationFile,
        `letter_${name}_${date}.pdf`
      );
    }
    form.append("signin_book", portfolioFile, `portfolio_${name}_${date}.pdf`);
    form.append("sigin_terms", "1");
    form.append("signin_period", periodSelected);
    form.append("sigin_language", lang.toUpperCase());
    form.append("signin_reference", reference.length ? reference : "N/A");

    setIsSending(true);
    axios({
      method: "POST",
      url: 
        // ? "https://cobertizo-dev-cms.alucinaprojects.com/api-services"
        "https://cms.cobertizo.com.mx/api-services/",
      data: form,
    })
      .then((res) => {
        if (res.data.status === 200) {
          setSuccessSent(true);

          window.dataLayer.push({
            event: "envio_formulario",
            program_name: program.menu.es,
          });
        } else if (res.data.status === 400) {
          setError(
            lang === "es"
              ? "Correo electrónico ya ha sido registrado"
              : "this email is already in use"
          );
        } else if (res.data.status === 500) {
          setError("Error desconocido");
        }
        setIsSending(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const executeCaptcha = () => {
    isDev ? onClick() : captchaRef.current.execute();
  };

  if (isSending)
    return (
      <div className="form center-form">
        <h1>{lang === "es" ? "Enviando datos..." : "Sending..."}</h1>
      </div>
    );

  if (successSent)
    return (
      <div className="form center-form">
        <h1>
          {lang === "es"
            ? "¡Tu solicitud ha sido enviada! gracias."
            : "Your application has been submitted, thank you!"}
        </h1>
        <div className="form-buttons">
          <div className="btn back-btn" onClick={props.onBack}>
            {lang === "es" ? "Regresar" : "Go back"}
          </div>
        </div>
      </div>
    );

  let programList = periods instanceof Array ? periods : [];
  let periodList = [];
  for (let program of programList) {
    for (let period of program) {
      if (period.status !== "inactive") {
        periodList.push({ value: period.title[lang], key: period.title[lang] });
      }
    }
  }

  return (
    <div className="form">
      <h1>{lang === "es" ? "Registro" : "Application form"}</h1>
      <div className="form-container">
        <div className="form-row mobile-row-input">
          <div className="input-field">
            <label>{lang === "es" ? "Correo electrónico" : "E-mail"}</label>
            <input
              className="input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              maxLength="100"
            />
          </div>
          <div className="input-field">
            <label>
              {lang === "es" ? "Elige un periodo" : "Select a period"}
            </label>
            <SelectInput
              value={periodSelected}
              placeholder={lang === "es" ? "No seleccionado" : "No selected"}
              data={periodList}
              onChange={(key) => setPeriodSelected(key)}
            />
          </div>
        </div>

        <div className="form-row mobile-row-input">
          <div className="input-field">
            <label>{lang === "es" ? "Nombre" : "Name"}</label>
            <input
              className="input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              maxLength="100"
            />
          </div>
          <div className="input-field">
            <label>{lang === "es" ? "Apellidos" : "Last name"}</label>
            <input
              className="input"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              maxLength="100"
            />
          </div>
        </div>

        <div className="input-field">
          <label>
            {lang === "es" ? "Fecha de nacimiento" : "Date of birth"}
          </label>
          <div className="form-row date-row">
            <input
              className="input input-number"
              value={day}
              onChange={(e) => {
                let value = Number(e.target.value);
                value = value > 31 ? 31 : value;
                value = value < 1 ? 1 : value;
                setDay(value);
              }}
              type="number"
              min={1}
              max={31}
            />
            <span>/</span>
            <input
              className="input input-number"
              value={month}
              onChange={(e) => {
                let value = Number(e.target.value);
                value = value > 12 ? 31 : value;
                value = value < 1 ? 1 : value;
                setMoth(value);
              }}
              type="number"
              min={1}
              max={12}
            />
            <span>/</span>
            <input
              className="input input-number year-input"
              value={year}
              onChange={(e) => {
                if (e.target.value.length > 4) return;
                setYear(Number(e.target.value));
              }}
              type="number"
              min={1900}
              max={currentYear}
            />
          </div>
        </div>

        <div className="form-row mobile-row">
          <div className="input-field">
            <label>{lang === "es" ? "Nacionalidad" : "Nationality"}</label>
            <SelectInput
              value={nationality}
              placeholder={lang === "es" ? "No seleccionado" : "No selected"}
              data={countries}
              onChange={(key) => setNationality(key)}
            />
          </div>
          <div className="input-field">
            <label>
              {lang === "es" ? "País de residencia" : "Country of residence"}
            </label>
            <SelectInput
              value={residence}
              placeholder={lang === "es" ? "No seleccionado" : "No selected"}
              data={countries}
              onChange={(key) => setResidence(key)}
            />
          </div>
        </div>

        <div className="form-row files-row">
          <div className="input-field">
            <label>{lang === "es" ? "CV / Semblanza" : "CV / semblance"}</label>
            <span style={{ opacity: 0 }}>X</span>
            <FileInput
              description="Cargar"
              value={bioFile}
              onChange={(file) => setBioFile(file)}
              inputName="CV/Semblanza"
              onError={(error) => setError(error)}
            />
          </div>

          {program?.id !== "p2" && (
            <div className="input-field">
              <label>
                {lang === "es" ? "Carta de motivos" : "Personal Statement"}
              </label>
              <span>
                (
                {lang === "es"
                  ? "Una cuartilla max, PDF"
                  : "PDF -  1 page max."}
                )
              </span>
              <FileInput
                description="Cargar"
                value={presentationFile}
                onChange={(file) => setPresentationFile(file)}
                inputName="Carta de motivos"
                onError={(error) => setError(error)}
              />
            </div>
          )}

          <div className="input-field">
            <label>{lang === "es" ? "Portafolio" : "Portfolio"}</label>
            <span>(PDF 5MB max.)</span>
            <FileInput
              description="Cargar"
              value={portfolioFile}
              onChange={(file) => setPortfolioFile(file)}
              inputName="Portafolio"
              onError={(error) => setError(error)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="input-field w-100">
            <label>
              {lang === "es"
                ? "¿Cómo te enteraste de nosotros?"
                : "How did you hear about us?"}
            </label>
            <input
              className="input"
              value={reference}
              onChange={(e) => setReference(e.target.value)}
              maxLength="300"
            />
          </div>
        </div>

        {program?.id === "p2" && (
          <>
            <div className="form-row">{renderMassInstructions(lang)}</div>

            <div className="form-row">
              <div className="input-field">
                <label>
                  {lang === "es"
                    ? "Formato de aplicación"
                    : "Application format "}
                </label>
                <span>
                  (
                  {lang === "es"
                    ? "Una cuartilla max, PDF"
                    : "PDF -  1 page max."}
                  )
                </span>
                <FileInput
                  description="Cargar"
                  value={massFile}
                  onChange={(file) => setMassFile(file)}
                  inputName="Carta de motivos"
                  onError={(error) => setError(error)}
                />
              </div>
            </div>
          </>
        )}

        <div className="form-buttons">
          <div className="btn back-btn" onClick={props.onBack}>
            {lang === "es" ? "Regresar" : "Go back"}
          </div>
          <div className="btn send-btn" onClick={executeCaptcha}>
            {lang === "es" ? "Enviar" : "Send"}
          </div>
        </div>
        <span className="error">{error}</span>
      </div>
      <ReCAPTCHA
        ref={captchaRef}
        sitekey="6LcHTF8aAAAAAPc9SYiK8ad2PHlXp1T7K0A_rHp2"
        size="invisible"
        onChange={onClick}
      />
    </div>
  );
};

export default Form;
