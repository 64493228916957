import React, { useState, useEffect } from "react";
import teals from "../../assets/imgs/home/element_03.png";
import diamond from "../../assets/imgs/us/element_01.png";
import MainInfo from "./components/MainInfo";
import SecondaryInfo from "./components/SecondaryInfo";
import Form from "./components/Form";

import "./index.css";

const programFilters = [
  { text: { es: "Programa", en: "Program" }, action: "detail" },
  { text: { es: "Estructura", en: "Structure" }, action: "structure" },
  { text: { es: "Mentores", en: "Mentors" }, action: "mentors" },
  { text: { es: "Testimonios", en: "Testimonials" }, action: "testimonials" },
];

const Programs = (props) => {
  let { programas } = props.data,
    { lang } = props;

  const [currentProgram, setCurrentProgram] = useState(undefined);
  const [isHoverScrollBtn, setIsHoverScrollBtn] = useState("");
  const onMouseEnter = () => setIsHoverScrollBtn("active");
  const onMouseLeave = () => setIsHoverScrollBtn("");
  const [currentFilter, setCurrentFilter] = useState("detail");
  const [showForm, setShowForm] = useState(false);

  let program = programas[currentProgram];

  useEffect(() => {
    if (props.pathname === "/apply") {
      setShowForm(true);
    }
  }, [props.pathname]);

  useEffect(() => {
    if (props.program !== currentProgram) {
      setCurrentProgram(props.program);
      setCurrentFilter("detail");
      setShowForm(false);
    }
  }, [props.program, currentProgram]);

  if (showForm) {
    let periods = program
      ? program.periods
      : [
          {
            title: {
              es: "Periodo 1",
              en: "Period 1",
            },
            subtitle: {
              es: null,
              en: null,
            },
            startDate: "2024-05-07",
            endDate: "2024-05-27",
            status: "active",
            label: {
              es: "Del 7 de mayo al 27 de mayo de 2024",
              en: "From May 7th to May 27th, 2024",
            },
          },
          {
            title: {
              es: "Periodo 2",
              en: "Period 2",
            },
            subtitle: {
              es: null,
              en: null,
            },
            startDate: "2024-07-09",
            endDate: "2024-07-29",
            status: "active",
            label: {
              es: "Del 9 de julio al 29 de julio de 2024",
              en: "From July 9th to July 29th, 2024",
            },
          },
          {
            title: {
              es: "Period 3",
              en: "Period 3",
            },
            subtitle: {
              es: null,
              en: null,
            },
            startDate: "2024-09-03",
            endDate: "2024-09-23",
            status: "active",
            label: {
              es: "Del 3 de septiembre al 23 de septiembre de 2024",
              en: "From September 3rd to September 23rd, 2024",
            },
          },
          {
            title: {
              es: "Periodo 4",
              en: "Period 4",
            },
            subtitle: {
              es: null,
              en: null,
            },
            startDate: "2024-11-12",
            endDate: "2024-12-02",
            status: "active",
            label: {
              es: "Del 12 de noviembre al 2 de diciembre de 2024",
              en: "From November 12th to December 2nd, 2024",
            },
          },
        ];
    periods = periods.map((period) => {
      let title = period.title;
      let year = new Date(period.startDate).getFullYear();
      return {
        ...period,
        title: {
          es: `${title.es} ${year}`,
          en: `${title.en} ${year}`,
        },
      };
    });
    return (
      <div id="programs" className="form-mobile">
        <div className="form-divider border-right" />
        <Form
          periods={[periods]}
          program={program}
          lang={lang}
          onBack={() => {
            setShowForm(false);
            props.onUnlockScroll();
            if (!props.mobile) props.onFormActive();
            if (process.env.NODE_ENV === "production")
              window.ga("send", "event", "Boton", "Click", "GoBack");
          }}
        />
        <div className="form-divider border-left" />
      </div>
    );
  }
  return (
    <div>
      {!currentProgram ? (
        <div id="programs" className="programs-mobile">
          <div id="main-info">
            <div className="program-title">
              <span className="mobile-title">
                {lang === "es" ? "Programas" : "Programs"}
              </span>
              <span className="desktop-title ppc-title">
                {lang === "es" ? "Programas" : "Programs"}
              </span>
            </div>
            <div className="program-main-content">
              <div className="programas-gral-description">
                <p>
                  {lang === "es"
                    ? "Las residencias buscan proveer un espacio profesional de trabajo y conformar una comunidad de intercambio y reflexión en torno a las prácticas artísticas, tanto para artistas que realicen proyectos de manera individual como para proyectos colaborativos (máx. dos personas)."
                    : "The residencies seek to provide a professional work space and build a community of exchange and reflection around art practices, for individual projects as well as for duo projects."}
                </p>
                <p>
                  {lang === "es"
                    ? "Las residencias tienen una duración de 3 semanas en las que lxs artistas recibirán todos los servicios necesarios (hospedaje, transporte y comida) para poder enfocarse plenamente en la producción y desarrollo de sus proyectos, y se incentivará la reflexión crítica a partir de las sesiones con mentorxs y el intercambio entre residentxs."
                    : "The programs are three weeks long in which the residents will be provided with all services needed (hosting, transport and meals) in order to fully engage with their projects. Professional artists and curators will guide critical mentorships over the work of the residents."}
                </p>
              </div>
            </div>
            <div className="program-filters program-buttons-container">
              {Object.values(programas).map((program, index) => {
                let title =
                  program.detail && program.detail.title
                    ? program.detail.title
                    : { es: "", en: "" };
                title = title[lang];
                return (
                  <span
                    key={index}
                    onClick={() => {
                      setCurrentProgram(program.id);
                      props.onChangeProgram(program.id);
                    }}
                    className="program-button"
                    dangerouslySetInnerHTML={{ __html: title }}
                  ></span>
                );
              })}
            </div>
            <div className="program-gral-description-bottom">
              <img src={diamond} alt="diamante" className="image" />
            </div>
          </div>
          <div id="secondary-info">
            <div className="periods">
              <div className="program-subinfo-content">
                <div className="subinfo-container">
                  <div className="programs-image-container">
                    <div className="programs-gral-image" />
                  </div>
                  <div className="bottom-programs-image">
                    <img src={teals} alt="tejas" className="image" />
                  </div>
                </div>
              </div>
              <div className="scroll-section">
                <div
                  className="scroll-btn scroll-btn-tall"
                  onClick={props.onNext}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                >
                  <span className="icon icon-big-arrow-right arrow-btn" />
                  <span className={`hover ${isHoverScrollBtn}`}>
                    {lang === "es" ? "Ubicación" : "Location"}
                  </span>
                </div>
                <div
                  className="scroll-btn scroll-btn-small"
                  onClick={props.onPrev}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                >
                  <span className="icon icon-big-arrow-left arrow-btn" />
                  <span className={`hover ${isHoverScrollBtn}`}>
                    {lang === "es" ? "Nosotros" : "Us"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id="programs" className="programs-mobile">
          <MainInfo
            onChangeProgram={(program) => setCurrentProgram(program)}
            programFilters={programFilters}
            currentProgram={currentProgram}
            currentFilter={currentFilter}
            setCurrentFilter={setCurrentFilter}
            program={program}
            onLockScroll={props.onLockScroll}
            onUnlockScroll={props.onUnlockScroll}
            onApply={() => {
              setShowForm(true);
              // props.onLockScroll();
              if (!props.mobile) props.onFormActive();
            }}
            onNext={props.onNext}
            lang={lang}
          />
          <SecondaryInfo
            currentProgram={currentProgram}
            program={program}
            onApply={() => {
              setShowForm(true);
              // props.onLockScroll();
              if (!props.mobile) props.onFormActive();
            }}
            onNext={props.onNext}
            onPrev={props.onPrev}
            lang={lang}
          />
        </div>
      )}
    </div>
  );
};

export default Programs;
