import React from "react";
import KevinJackEN from "../../../../app/assets/imgs/programs/KevinJack-en.png";
import KevinJackES from "../../../../app/assets/imgs/programs/KevinJack-es.png";

const SubInfo = (props) => {
  let { periods = [], cost, lang, cost_2, isScholarship, id } = props;
  const options = {
    month: "long",
    day: "numeric",
  };

  console.log(id);

  if (isScholarship) {
    periods = [
      ...periods,
      // null,
      // {
      //   title: {
      //     es: "Convocatoria abierta",
      //     en: "Open call",
      //   },
      //   subtitle: {
      //     es: "La convocatoria se abrirá a partir de mayo de 2024",
      //     en: "The open call will be starting May 2024",
      //   },
      //   startDate: "2024-05-01",
      //   endDate: "2024-06-28",
      //   status: "active",
      //   label: {
      //     es: "de 30 de mayo al 28 de Junio 2024",
      //     en: "From May 30th to June 28th, 2024",
      //   },
      // },
    ];
  }

  const type = lang === "es" ? "es-MX" : "en-US";
  let priceLabel = lang === "es" ? "1 artista: " : "Solo project: ";
  if (isScholarship) {
    priceLabel = lang === "es" ? "Sin costo" : "No cost";
  }

  return (
    <div className="subinfo-container">
      <div className="dates-container">
        {periods.map((date, index) =>
          date === null ? (
            <div key={index} className={`date-item`} />
          ) : (
            <div key={index} className={`date-item ${date.status}`}>
              <h2>{date.title[lang]}</h2>
              <p>{date.subtitle[lang]}</p>
              {date.label && date.label[lang] ? (
                <div className="fulldate-container">
                  <span>{date.label[lang]}</span>
                </div>
              ) : (
                <div className="fulldate-container">
                  {lang === "es" ? "Del" : "From"}
                  <span>
                    {" "}
                    {new Date(date.startDate).toLocaleDateString(type, options)}
                    {lang === "es" ? " al" : " to"}{" "}
                    {new Date(date.endDate).toLocaleDateString(type, {
                      ...options,
                      year: "numeric",
                    })}{" "}
                  </span>
                </div>
              )}
            </div>
          )
        )}
      </div>
      <div className="subinfo-buttons">
        {id !== "p2" && (
          <div className="cost full-background-contain">
            <div className="cost-info">

              <span className="title">{lang === "es" ? "COSTO" : "TUITION"}</span>
              <span className="subtitle">
                {lang === "es" ? "3 semanas" : "3 weeks"}
              </span>
              <span className="price">
                {id !== "p2" ? `${priceLabel} ${cost}` : cost}
              </span>
              {typeof cost_2 === "string" && Boolean(cost_2.trim().length) && (
                <span className="price">
                  {lang === "es" ? "2 artistas" : "Duo project"}: {cost_2}
                </span>
              )}
            </div>
          </div>
        )}
        {id === "p2" && (
          <div className="cost-image">
            <div className="image">
            <img src={lang === "es" ? KevinJackES : KevinJackEN} alt="Kevin & Jack"/>
            </div>
          </div>
        )}
        <div
          className={
            "apply-btn" +
            (periods.every((date) => date && date.status === "inactive")
            ? " off"
            : "")
          }
          >
          <div className="btn" onClick={props.onApply}>
            <span style={{ display: "flex", alignItems: "center" }}>
              <span className="icon icon-double-chevron-right" />
              <p>
                {periods.every((date) => date && date.status === "inactive")
                  ? lang === "es"
                    ? "Aplicaciones cerradas"
                    : "Applications closed"
                  : lang === "es"
                  ? "Aplicar"
                  : "Apply"}
              </p>
              <span className="icon icon-double-chevron-left" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubInfo;

/*
let { } = props.data

*/
