import React from "react";

const ProgramDetail = (props) => {
  let data = props["detail"],
    { lang, comingSoon } = props;

  comingSoon = Boolean(comingSoon);

  let description = data.description ? data.description[lang] : "";
  let includes = data.includes ? data.includes[lang] : "";
  let not_includes = data.not_includes ? data.not_includes[lang] : "";

  /* if (typeof description === "string") {
    description = description.replaceAll(
      "Cobertizo Grant",
      "<span class='font_ayarkut_'>Cobertizo Grant</span>"
    );
  } */

  return (
    <div>
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      {comingSoon === false && (
        <div className="includes_desc">
          {includes && (
            <div className="includes">
              <h3>{lang === "es" ? "Incluye" : "Includes"}</h3>
              <div dangerouslySetInnerHTML={{ __html: includes }} />
            </div>
          )}
          {not_includes && (
            <div className="not_includes">
              <h3>{lang === "es" ? "No incluye" : "Doesn’t include"}</h3>
              <div dangerouslySetInnerHTML={{ __html: not_includes }} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProgramDetail;
